import { Form, Button, Table } from "react-bootstrap";
import { Navbar } from '../components';
import { useState } from "react";

export function SearchPage () {
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');

    const [SearchResults, setSearchResults] = useState([]);

    const [SearchFinished, setSearchFinished] = useState(false);

    function search () {
        fetch('/api/customers/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: FirstName,
                lastName: LastName,
                email: Email
            }),
        }).then(function (stream) {
            stream.json().then(function (data) {
                setSearchResults(data.results);

                setSearchFinished(true);
            });
        });
    }

    return (
        <div>
            <Navbar />

            <div className="container-fluid" style={{ padding: '25px' }}>
                {!SearchFinished ? (
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="John" value={FirstName} onChange={e => setFirstName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Doe" value={LastName} onChange={e => setLastName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="johndoe@gmail.com" value={Email} onChange={e => setEmail(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" style={{ width: '100%' }} onClick={search}>
                            Search
                        </Button>
                    </Form>
                ) : (
                    <div>
                        <h1 className="h4">Search Results</h1>

                        <Table striped bordered hover style={{ marginTop: '20px' }}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {SearchResults.map(result => {
                                    return (
                                        <tr key={result.id}>
                                            <td>{result.name}</td>

                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                {result.email}

                                                <Button size='sm' style={{ marginLeft: 'auto' }} href={`/customers?id=${result.id}`}>
                                                    Lookup
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
}