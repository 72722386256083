import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'

import { ResultsPage, SearchPage } from './pages'

const root = ReactDOM.createRoot(document.getElementById('root'))

function Router() {
	return window.location.pathname.startsWith('/customers') ? (
		<ResultsPage />
	) : window.location.pathname.startsWith('/search') ? (
		<SearchPage />
	) : (
		<App />
	)
}

root.render(
	<React.StrictMode>
		<Router />
	</React.StrictMode>
)
