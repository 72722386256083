import { Navbar } from './components'
import { ScanPage } from './pages'

function App() {
	return (
		<div className='App'>
			<div
				style={{ position: 'absolute', width: '100vw', zIndex: '1000' }}
			>
				<Navbar />
			</div>

			<ScanPage />
		</div>
	)
}

export default App
