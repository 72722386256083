import { Navbar as BootstrapNavbar, Container, Nav } from 'react-bootstrap';

export function Navbar () {
    return (
        <div style={{ zIndex: '300' }}>
            <BootstrapNavbar expand='lg' className='bg-body-tertiary' style={{ zIndex: '400' }}>
                <Container fluid>
                    <BootstrapNavbar.Brand
                        href='/'
                        style={{ cursor: 'pointer' }}
                    >
                        ASFL Ticketing System
                    </BootstrapNavbar.Brand>
                    <BootstrapNavbar.Toggle aria-controls='basic-navbar-nav' />
                    <BootstrapNavbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto'>
                            <Nav.Link
                                href='/search'
                                style={{ cursor: 'pointer' }}
                            >
                                Search
                            </Nav.Link>
                        </Nav>
                    </BootstrapNavbar.Collapse>
                </Container>
            </BootstrapNavbar>
        </div>
    );
}