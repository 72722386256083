import { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

export function ScanPage () {
    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 5,
        });

        scanner.render(success, error);

        function success (result) {
            scanner.clear();

            window.location.href = `/customers?id=${result}`;
        }

        function error (err) {
            console.warn(err);
        }
    });

    return (
        <div
            style={{
                height: 'calc(100vh - 56px)',
                width: '100vw',
                position: 'absolute',
                zIndex: '100',
                top: '56px',
            }}
        >
            <div
                id='reader'
                style={{
                    height: '100%',
                    width: '100%',
                    // display: ScannerResults ? 'none' : 'block',
                }}
            ></div>
        </div>
    );
}