import { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { Navbar } from "../components";

export function ResultsPage () {
    const [ScannerResults, setScannerResults] = useState(null);
    const [CustomerData, setCustomerData] = useState(null);

    function fetchCustomerData (customerId) {
        setScannerResults(customerId);

        try {
            fetch('/api/customers/fetch', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: customerId,
                }),
            }).then(function (stream) {
                stream.json().then(function (data) {
                    setCustomerData(data.customer);
                });
            });
        } catch (err) { }
    }

    function checkInCustomer () {
        try {
            if (!ScannerResults) return;

            fetch('/api/customers/check-in', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: ScannerResults,
                }),
            }).then(function (stream) {
                stream.json().then(function (data) {
                    if (data.error) alert(data.error);

                    fetchCustomerData(ScannerResults);
                });
            });
        } catch (err) { }
    }

    function close () {
        window.location.href = '/';
    }

    const query = new URLSearchParams(window.location.search);

    useEffect(function () {
        const customerId = query.get('id');

        fetchCustomerData(customerId);
    }, []);

    return (
        <div>
            <Navbar />

            {ScannerResults && CustomerData && (
                <div
                    style={{
                        height: 'calc(100vh - 56px)',
                        width: '100vw',
                        position: 'absolute',
                        zIndex: '200',
                        top: '56px',
                        backgroundColor: 'white',
                        zIndex: '100'
                    }}
                >
                    <div style={{ padding: '15px' }}>
                        <Card body>
                            <div>
                                <h1 className='h4'>Name</h1>

                                <p style={{ margin: '0', padding: "0" }}>
                                    {CustomerData.name}
                                </p>
                            </div>

                            <div style={{ paddingTop: '20px' }}>
                                <h1 className='h4'>Email</h1>

                                <p style={{ margin: '0', padding: "0" }}>
                                    {CustomerData.email}
                                </p>
                            </div>

                            <div style={{ paddingTop: '20px' }}>
                                <h1 className='h4'>Ticket Type</h1>

                                <p style={{ margin: '0', padding: "0" }}>
                                    {CustomerData.type == '0'
                                        ? 'General Admission'
                                        : 'VIP Admission'}
                                </p>
                            </div>

                            <div style={{ paddingTop: '20px' }}>
                                <h1 className='h4'>Checked In</h1>

                                <p style={{ margin: '0', padding: "0" }}>
                                    {CustomerData.checkedIn == 0
                                        ? 'False'
                                        : 'True'}
                                </p>
                            </div>
                        </Card>

                        <hr />

                        <Button
                            onClick={checkInCustomer}
                            style={{ width: '100%' }}
                        >
                            Check-In
                        </Button>

                        <Button
                            onClick={close}
                            style={{ width: '100%', marginTop: '10px' }}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}